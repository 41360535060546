import { CountryISO } from '@shared/components/custom-phone-input/enums/country-iso.enum';

export const environment = {
  version: 'v2.92.0',
  production: false,
  domainAPI: 'https://dev-capteus-api.azurewebsites.net/webApi',
  domain: 'https://dev-capteus-api.azurewebsites.net',
  preferredCountries: [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Denmark],
  encodedZeroIdString: '0',
  mailLogo: '##mail_logo',
  fileSizeLimit: 20,
  smsReplyToNumber: '+353863876900',
  editorConfig: {
    editable: true,
    spellcheck: false,
    height: '150px',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    fonts: [
      'Arial',
      'Helvetica',
      'Times New Roman',
      'Verdana',
      'Sans Serif',
      'Serif',
      'Monospace',
      'Georgia',
      'Trebuchet MS',
      'Courier New',
      'Arial Black',
      'Comic Sans MS',
      'Impact',
      'Lucida Sans Unicode',
      'Tahoma',
    ],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [[], ['fontName', 'insertVideo']],
  },
  dynamicDyalogGlobalConfig: {
    dismissableMask: true,
    baseZIndex: 10000,
    focusOnShow: false,
  },
};
