import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginService } from '@core/services/login.service';
import { HttpService } from '@core/services/http.service';
import { UserService } from '@core/services/user.service';
import { AuthService } from '@core/services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocaleService } from '@core/services/locale.service';
import { PermissionService } from '@core/services/permission.service';
import { LoaderService } from '@core/services/loader.service';
import { MessageService } from 'primeng/api';
import { GroupInterceptor } from '@core/interceptors/group.interceptor';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { ConfigService } from '@core/services/app.config.service';
import { GroupService } from '@core/services/group.service';
import { GroupApiService } from '@core/services/group-api.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationsService } from '@shared/service/notifications.service';
import { BreadcrumbService } from '@private/services/breadcrumb.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormConfigService } from '@shared/service/form-config.service';
import { TableConfigService } from '@shared/service/table-config.service';

export function configProviderFactory(provider: ConfigService) {
  return () => provider.loadTenant();
}

@NgModule({
  declarations: [],
  imports: [CommonModule, BrowserAnimationsModule],
  exports: [],
  providers: [
    MessageService,
    HttpService,
    UserService,
    LoginService,
    AuthService,
    LocaleService,
    PermissionService,
    GroupService,
    GroupApiService,
    LoaderService,
    ConfigService,
    NotificationsService,
    BreadcrumbService,
    DynamicDialogConfig,
    FormConfigService,
    TableConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configProviderFactory,
      deps: [ConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: GroupInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class CoreModule {}
