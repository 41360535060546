import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

function transform(body: any): string {
  let string = '';

  for (const [key, val] of Object.entries(body)) {
    if (Array.isArray(val)) {
      isDevMode() && console.warn('Key has array: ', key);
      val.forEach(el => (string += `${key}=${el}&`));
    } else {
      string += `${key}=${val}&`;
    }
  }

  return string.slice(0, -1);
}

@Injectable()
export class HttpService {
  private endpoint: string = environment.domainAPI;
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  private headersJSON: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private _http: HttpClient) {}

  /**в
   * Performs a request with `get` http method.
   * @param path
   * @param params
   * @returns {Observable<any>}
   */
  get(path: string, params: Object = {}): Observable<any> {
    if (typeof params !== 'object') {
      console.error('Type of params in GET method is not object. Path:', path);
    }

    const reqOpts = {
      params: new HttpParams(),
      headers: this.headers,
      withCredentials: true,
    };

    for (const [key, val] of Object.entries(params)) {
      if (isDevMode() && val === undefined) {
        console.warn(`Error will happen due to absent val for "${key}" in ${path}`);
      }
      reqOpts.params = reqOpts.params.set(key, val === null ? null : val.toString());
    }

    return this._http.get(`${this.endpoint}/${path}`, reqOpts).pipe(catchError(this.errorHandler));
  }

  /**
   * Performs a request with `get` http method.
   * @param path
   * @param data
   * @returns {Observable<any>}
   */
  post(path: string, data: Object = {}): Observable<any> {
    return this._http
      .post(`${this.endpoint}/${path}`, data, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .pipe(catchError(this.errorHandler));
  }

  postFormData(path: string, data: FormData): Observable<any> {
    return this._http.post(`${this.endpoint}/${path}`, data, { withCredentials: true }).pipe(catchError(this.errorHandler));
  }

  /**
   * Performs a request with `get` http method.
   * @param path
   * @param data
   * @returns {Observable<any>}
   */
  postJSON(path: string, data: Object): Observable<any> {
    return this._http
      .post(`${this.endpoint}/${path}`, data, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .pipe(catchError(this.errorHandler));
  }

  /**
   * Performs a request with `get` http method.
   * @param path
   * @param data
   * @param body
   * @returns {Observable<any>}
   */
  postFile(path: string, data: { [key: string]: any; fileUrl?: string }, body: any): Observable<any> {
    if (data.fileUrl) {
      data.fileUrl = encodeURIComponent(data.fileUrl);
    }
    const params = transform(data);
    const formData = new FormData();
    if (body) {
      Array.isArray(body) ? body.forEach(file => formData.append('files', file)) : formData.append('file', body);
    }

    return this._http.post(`${this.endpoint}/${path}?${params}`, formData, {
      withCredentials: true,
    });
  }

  /**
   * Performs a request with `get` http method.
   * @param path
   * @param body
   * @returns {Observable<any>}
   */
  postFileWithoutData(path: string, body: any): Observable<any> {
    const formData = new FormData();
    if (body) {
      Array.isArray(body) ? body.forEach(file => formData.append('files', file)) : formData.append('file', body);
    }

    return this._http.post(`${this.endpoint}/${path}`, formData, {
      withCredentials: true,
    });
  }

  /**
   * Performs a request with `get` http method.
   * @param path
   * @param body
   * @returns {Observable<any>}
   */
  postAttachment(path: string, body: any): Observable<any> {
    const formData = new FormData();
    if (body) {
      Array.isArray(body) ? body.forEach(file => formData.append('attachments', file)) : formData.append('attachment', body);
    }

    return this._http.post(`${this.endpoint}/${path}`, formData, {
      withCredentials: true,
    });
  }

  /**
   * Performs a request with `get` http method.
   * @param path
   * @param data
   * @param body
   * @returns {Observable<any>}
   */
  postTemplate(path: string, data: Object, files: Object): Observable<any> {
    const formData = new FormData();
    if (files) {
      for (const key of Object.keys(files)) {
        if (files[key]) {
          formData.append(key, files[key]);
        }
      }
    }

    let params = new HttpParams();
    for (const [key, val] of Object.entries(data)) {
      params = params.set(key, val.toString());
    }

    return this._http.post(`${this.endpoint}/${path}`, formData, {
      params,
      withCredentials: true,
    });
  }

  /**
   * Performs a request with `post` http method.
   * @param path
   * @param data
   * @returns {Observable<any>}
   */
  downloadPost(path: string, data: Object): Observable<any> {
    return this._http
      .post(`${this.endpoint}/${path}`, data, {
        headers: this.headersJSON,
        responseType: 'blob',
        observe: 'response',
        withCredentials: true,
      })
      .pipe(catchError(this.errorHandler));
  }

  /**
   * Performs a request with `get` http method.
   * @param path
   * @param params
   * @returns {Observable<any>}
   */
  download(path: string, params: Object = {}): Observable<any> {
    if (typeof params !== 'object') {
      console.error('Type of params in GET method is not object. Path:', path);
    }

    const reqOpts = {
      params: new HttpParams(),
      headers: this.headers,
      withCredentials: true,
      responseType: 'blob',
      observe: 'response',
    };

    for (const [key, val] of Object.entries(params)) {
      if (Array.isArray(val)) {
        for (let i = 0; i < val.length; i++) {
          reqOpts.params = reqOpts.params.append(key, val[i].toString());
        }
      } else {
        reqOpts.params = reqOpts.params.set(key, val.toString());
      }
    }

    return this._http.get(`${this.endpoint}/${path}`, reqOpts as any).pipe(catchError(this.errorHandler));
  }

  /**
   * Performs a request with `delete` http method.
   * @param path
   * @param id
   * @returns {Observable<any>}
   */
  delete(path: string, id: any): Observable<any> {
    return this._http.delete(`${this.endpoint}/${path}/${id}`, {
      withCredentials: true,
    });
  }

  /**
   * Performs a request with `delete` http method.
   * @param path
   * @returns {Observable<any>}
   */
  deleteWithoutParams(path: string): Observable<any> {
    return this._http.delete(`${this.endpoint}/${path}`, {
      withCredentials: true,
    });
  }

  /**
   * Performs a request with `delete` http method.
   * @param path
   * @param data
   * @returns {Observable<any>}
   */
  deleteWithParams(path: string, data: any): Observable<any> {
    let params = new HttpParams();
    for (const [key, val] of Object.entries(data)) {
      params = params.set(key, val.toString());
    }
    return this._http.delete(`${this.endpoint}/${path}`, {
      params,
      withCredentials: true,
    });
  }

  /**
   * Performs a error handler.
   * @param error
   * @returns {Observable<any>}
   */
  private errorHandler(error: any): Observable<any> {
    return throwError(error);
  }
}
