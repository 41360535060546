import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@core/services/http.service';
import { IAssigner } from '@shared/models/IAssigner';
import { IApiResponse } from '@shared/models/IApiResponse';
import { IGroup } from '@core/models/IGroup';
import { IGroupAssetType } from '@core/models/IAsset';

@Injectable()
export class GroupApiService {
  path = 'group';

  constructor(private http: HttpService) {}

  // WTC: Home/Claims - Open tab "View claims", open record, click "View Task" button
  getUsers(idInsured: string = '0', clientAccess: boolean = false): Observable<IApiResponse<IAssigner[]>> {
    return this.http.get(`${this.path}/getUsers`, { idInsured, clientAccess });
  }

  // WTC:
  getAssetTypes(): Observable<IApiResponse<IGroupAssetType[]>> {
    return this.http.get(`${this.path}/getAssetTypes`);
  }

  // DESC: Get groups of current user
  // WTC:
  getGroups(): Observable<IApiResponse<IGroup[]>> {
    return this.http.get(`${this.path}/getGroups`);
  }
}
