import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindowSizeService {
  private innerWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
  public innerWidth$ = this.innerWidth.asObservable();
  private isMobile: BehaviorSubject<boolean> = new BehaviorSubject(window.innerWidth < 768);
  public isMobile$ = this.isMobile.asObservable();

  constructor() {
    fromEvent(window, 'resize')
      .pipe(map(event => (event.target as Window).innerWidth))
      .subscribe(width => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        if (this.innerWidth.getValue() !== width) {
          this.innerWidth.next(width);
        }
        this.isMobile.next(width <= 768);
      });
  }
}
