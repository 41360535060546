@if (show | async) {
  <div id="app-loader">
    <div class="app-loader__container">
      <svg fill="none" height="375" viewBox="0 0 445 375" width="445" xmlns="http://www.w3.org/2000/svg">
        <path d="M88.0984 86.8V208.4H169.198V37.8L220.998 6.5V262.8H39.8984V115.9L88.0984 86.8Z" fill="url(#paint0_linear)" />
        <path d="M221.002 6.5L400.002 115.9V262.8H363.302V142.6L277.602 96.9V262.8H220.602L221.002 6.5Z" fill="url(#paint1_linear)" />
        <path d="M363.3 142.6L277 162.1V262.8H363.3V142.6Z" fill="url(#paint2_linear)" />
        <path d="M363.302 142.6L277.602 96.9004V162.1L363.302 142.6Z" fill="url(#paint3_linear)" />
        <path d="M88.3016 86.9004L169.202 114.6V208.4L88.1016 207.9L88.3016 86.9004Z" fill="url(#paint4_linear)" />
        <path
          class="logo-text"
          d="M82.1992 352.9L65.5992 327H49.2992V352.9H39.6992V286.4H65.8992C79.5992 286.4 88.5992 294.5 88.5992 306.7C88.5992 315.6 83.5992 322.3 75.2992 325.3L93.4992 352.9H82.1992V352.9ZM49.2992 319.1H64.0992C72.7992 319.1 78.5992 314.2 78.5992 306.7C78.5992 299.2 72.9992 294.3 64.6992 294.3H49.2992V319.1Z"
          fill="black" />
        <path
          class="logo-text"
          d="M101 289.2C101 286 103.6 283.5 106.8 283.6C110 283.7 112.5 286.2 112.4 289.4C112.4 292.5 109.8 295 106.7 295C103.6 295.1 101.1 292.6 101 289.6C101 289.4 101 289.3 101 289.2ZM102.2 304.9H111.4V352.9H102.2V304.9Z"
          fill="black" />
        <path
          class="logo-text"
          d="M122.399 348.5L125.999 342.8C130.099 345.4 134.999 346.7 139.799 346.6C145.499 346.6 149.199 344 149.199 340C149.199 336.2 145.299 334.2 139.999 332.6L135.399 331.4C127.599 329.2 122.199 326.3 122.199 318.8C122.199 309.9 129.399 304 140.099 304C146.899 304 152.499 305.4 157.299 308.6L153.299 314.7C149.499 312.4 145.199 311.2 140.699 311.3C135.299 311.3 131.599 313.7 131.599 317.5C131.599 321.1 134.999 322.8 139.699 324.2L144.299 325.4C151.999 327.5 158.599 331.3 158.599 339.3C158.599 348 151.099 353.8 139.599 353.8C132.699 353.8 126.499 352 122.399 348.5Z"
          fill="black" />
        <path
          class="logo-text"
          d="M185.8 331.5H178.7V352.9H169.5V282.8H178.7V323.9H185.9L203 304.9H214.6L193.4 327.6L215.8 352.9H204L185.8 331.5Z"
          fill="black" />
        <path class="logo-text" d="M214.699 286.4H224.999L248.099 341.1L270.999 286.4H281.199L252.399 352.9H243.399L214.699 286.4Z" fill="black" />
        <path
          class="logo-text"
          d="M287.102 289.2C287.102 286 289.702 283.5 292.902 283.5C296.102 283.5 298.602 286.1 298.602 289.3C298.602 292.4 296.102 294.9 292.902 295C289.802 295.1 287.202 292.7 287.102 289.6C287.102 289.5 287.102 289.3 287.102 289.2ZM288.202 304.9H297.402V352.9H288.202V304.9Z"
          fill="black" />
        <path class="logo-text" d="M311.199 282.8H320.399V352.9H311.199V282.8Z" fill="black" />
        <path class="logo-text" d="M334.398 282.8H343.598V352.9H334.398V282.8Z" fill="black" />
        <path
          class="logo-text"
          d="M400.902 332.3H364.302C365.202 341 369.802 346.6 378.102 346.6C383.502 346.6 388.702 344 391.902 339.6L398.902 343.4C394.402 350 387.002 353.9 378.202 353.9C364.102 353.9 354.602 344 354.602 329.2C354.602 314.2 363.802 304.1 377.902 304.1C392.002 304.1 401.102 313.8 401.102 328.4C401.002 329.6 401.002 330.9 400.902 332.3ZM364.302 326H391.602C390.802 316.8 385.702 311.4 377.802 311.4C369.902 311.4 365.102 316.9 364.302 326Z"
          fill="black" />
        <defs>
          <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear" x1="39.8684" x2="220.958" y1="134.675" y2="134.675">
            <stop stop-color="#191A1A" />
            <stop offset="1" stop-color="#484848" />
          </linearGradient>
          <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear" x1="220.562" x2="400.032" y1="134.675" y2="134.675">
            <stop stop-color="#363634" />
            <stop offset="0.62" stop-color="#383836" />
            <stop offset="0.84" stop-color="#3E3E3D" />
            <stop offset="0.99" stop-color="#484848" />
            <stop offset="1" stop-color="#494949" />
          </linearGradient>
          <linearGradient gradientUnits="userSpaceOnUse" id="paint2_linear" x1="277.05" x2="363.32" y1="202.715" y2="202.715">
            <stop stop-color="#FFA851" />
            <stop offset="1" stop-color="#FFE159" />
          </linearGradient>
          <linearGradient gradientUnits="userSpaceOnUse" id="paint3_linear" x1="277.562" x2="363.322" y1="129.54" y2="129.54">
            <stop stop-color="#FF752F" />
            <stop offset="1" stop-color="#FF752F" />
          </linearGradient>
          <linearGradient gradientUnits="userSpaceOnUse" id="paint4_linear" x1="88.0716" x2="169.212" y1="147.64" y2="147.64">
            <stop stop-color="#FF512F" />
            <stop offset="1" stop-color="#FFA018" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
}
