import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { tap } from 'rxjs/operators';
import { PermissionService } from '@core/services/permission.service';
import { Observable } from 'rxjs';
import { IApiResponse } from '@core/models/IApiResponse';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpService,
    private perm: PermissionService
  ) {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    if (tokens) {
      this.token = tokens.token;
      this.refreshToken = tokens.refreshToken;
      this.expireDate = tokens.expired;
    }
  }

  private _token: string;

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }

  private _refreshToken: string;

  get refreshToken(): string {
    return this._refreshToken;
  }

  set refreshToken(value: string) {
    this._refreshToken = value;
  }

  private _expireDate: Date;

  set expireDate(value: Date) {
    this._expireDate = value;
  }

  public refresh(): Observable<IApiResponse<any>> {
    return this.http.post('auth/refreshToken', { refreshToken: this.refreshToken }).pipe(
      tap(res => {
        this.perm.setPermissions(res.data.permissions);
        this.updateTokens(res.data);
      })
    );
  }

  public updateTokens(data?) {
    const { token, refreshToken, expired } = data ? data : JSON.parse(localStorage.getItem('tokens'));

    if (data) {
      localStorage.setItem('tokens', JSON.stringify({ token, refreshToken, expired }));
    }

    this.token = token;
    this.refreshToken = refreshToken;
    this.expireDate = expired;
  }

  public clear() {
    this.token = null;
    this.refreshToken = null;
    this.expireDate = null;
  }
}
