export interface IInsuranceState {
  insuranceInfoDirty: boolean;
  insuranceFinanceDirty: boolean;
  insuranceQuoteDirty: boolean;
  insuranceNextPanelId: string;
}

export const insuranceState: IInsuranceState = {
  insuranceInfoDirty: false,
  insuranceFinanceDirty: false,
  insuranceQuoteDirty: false,
  insuranceNextPanelId: '',
};
