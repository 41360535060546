import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { Injectable } from '@angular/core';

@Injectable()
export class GroupInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private user: UserService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.user.getUser;
    const currentUser = localStorage.getItem('currentUser');
    const storageGr = JSON.parse(localStorage.getItem('currentGroup'));
    if (
      request.url.includes('auth/signin') ||
      !user ||
      (user.currentGroup &&
        storageGr &&
        user.currentGroup.idGroup === storageGr.idGroup &&
        user.currentGroup.idLicenseOwner === storageGr.idLicenseOwner)
    ) {
      return next.handle(request);
    } else if (!currentUser && !storageGr) {
      return next.handle(request);
    } else if (user && currentUser && !storageGr) {
      this.user.logout();
      // this.notify.remove();
      // TODO: Add translation
      // this.notify.error('Authentication error. You have been logout');
      return throwError('Authentication error');
    } else {
      this.auth.updateTokens();
      this.user.setEntity = storageGr;
      // this.notify.remove();
      // TODO: Add translation
      // this.notify.info('', 'Current group is different. The newest group has been set');
      return throwError('The groups are different');
    }
  }
}
