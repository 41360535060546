import { Routes } from '@angular/router';
import { CoreModule } from '@app/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LabelsService } from '@private/services/labels.service';
import { ConfirmationService } from 'primeng/api';
import { OptionsService } from '@private/services/options.service';

export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import(/* webpackChunkName: "public" */ 'src/app/public/public.routes').then(m => m.PublicRoutes),
  },
  {
    path: 'panel',
    providers: [CoreModule, DialogService, DynamicDialogRef, LabelsService, ConfirmationService, OptionsService],
    loadChildren: () => import(/* webpackChunkName: "private" */ 'src/app/private/private.routes').then(m => m.PrivateRoutes),
  },
  { path: '**', redirectTo: '' },
];
