import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { ToastModule } from 'primeng/toast';
import { WindowSizeService } from '@shared/service/window-size.service';
import { ConfigService } from '@core/services/app.config.service';
import { EInterfaceToastPosition } from '@shared/enums/EInterface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  providers: [LoaderService],
  imports: [RouterOutlet, LoaderComponent, ToastModule],
})
export class AppComponent implements OnInit {
  constructor(
    private _loader: LoaderService,
    private _r: Router,
    private _windowSize: WindowSizeService,
    private _config: ConfigService
  ) {}

  get toastPosition() {
    if ((this._config.config && this._config.config.toastPosition) || this._config.config.toastPosition === 0) {
      switch (this._config.config.toastPosition) {
        case EInterfaceToastPosition.bottomCenter:
          return 'bottom-center';
        case EInterfaceToastPosition.bottomRight:
          return 'bottom-right';
        case EInterfaceToastPosition.bottomLeft:
          return 'bottom-left';
        case EInterfaceToastPosition.topLeft:
          return 'top-left';
        case EInterfaceToastPosition.topRight:
          return 'top-right';
        case EInterfaceToastPosition.topCenter:
          return 'top-center';
      }
    }
    return 'bottom-right';
  }

  ngOnInit() {
    this._windowSize.innerWidth$.pipe().subscribe();
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    this._r.events.subscribe((e: any) => {
      if (e instanceof NavigationStart) {
        this._loader.show();
      }
      if (e instanceof NavigationEnd || e instanceof NavigationError || e instanceof NavigationCancel) {
        this._loader.hide();
      }
    });
  }
}
