import { Injectable } from '@angular/core';
import { ILogin } from '@core/models/ILogin';
import { HttpService } from '@app/core';
import { Observable } from 'rxjs';
import { IUserProfile, IUserProfileUpdate } from '@core/models/IUser';
import { IResetPassword } from '@core/models/IPassword';
import { IChangePassword } from '@core/models/IChangePassword';
import { IApiResponse } from '@core/models/IApiResponse';
import { IAuditLog } from '@core/models/IAuditLog';
import { SupportIdentifyAdmin, SupportValidateAdmin } from '@core/models/IAdminLogin';
import { IMfaVerify } from '@core/models/IMfa';

@Injectable()
export class LoginService {
  private path = 'auth';

  constructor(private http: HttpService) {}

  // WTC:
  public login(credits: ILogin): Observable<IApiResponse<any>> {
    return this.http.post(`${this.path}/signin`, credits);
  }

  // WTC: Log Out
  public logout(refreshToken: string): Observable<IApiResponse<any>> {
    return this.http.post(`${this.path}/signout`, { refreshToken });
  }

  // WTC:
  public forgot(email: string): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/forgot`, { email });
  }

  // WTC:
  public reset(model: IResetPassword): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/resetPassword`, model);
  }

  // WTC:
  public changePassword(password: IChangePassword): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/changePassword`, password);
  }

  // WTC:
  public profileThemeUpdate(useDarkTheme: boolean): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/profileThemeUpdate`, useDarkTheme);
  }

  // WTC:
  public profileWidjetsConfigUpdate(widgetsConfig: string): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/profileWidjetsConfigUpdate`, {
      widgetsConfig,
    });
  }

  // WTC:
  public authyRequestCheck(requestId: string): Observable<IApiResponse<any>> {
    return this.http.get(`${this.path}/authyRequestCheck`, { requestId });
  }

  // WTC:
  public authyRegister(): Observable<IApiResponse<any>> {
    return this.http.post(`${this.path}/authyRegister`);
  }

  // WTC:
  public getProfile(): Observable<IApiResponse<IUserProfile>> {
    return this.http.get(`${this.path}/profile`);
  }

  // WTC:
  public updateProfile(data: IUserProfileUpdate): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/profileupdate`, data);
  }

  // WTC:
  public resetMfa(idLogin: any): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/resetMfa`, { idLogin: idLogin });
  }

  // WTC: Home/Claims - Open tab "View claims", open record and click "View change log" button
  public getAuditLog(idAuditEntityType, idEntity): Observable<IApiResponse<IAuditLog[]>> {
    return this.http.get('common/getEntityLog', { idAuditEntityType, idEntity });
  }

  // DESC: Updates is automated timetrack enabled setting
  public automatedTimetrackEnableUpdate(isAutomatedTimetrackEnabled: boolean): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/automatedTimetrackEnableUpdate`, {
      isAutomatedTimetrackEnabled,
    });
  }

  // DESC: Sends SMS with 2FA code to identify admin account
  public supportIdentifyAdmin(data: SupportIdentifyAdmin): Observable<IApiResponse<null>> {
    return this.http.post(`${this.path}/supportIdentifyAdmin`, data);
  }

  // DESC: Validates admin identity and generates auth token
  public supportValidateAdmin(data: SupportValidateAdmin): Observable<IApiResponse<any>> {
    return this.http.post(`${this.path}/supportValidateAdmin`, data);
  }

  public oneLogin(): Observable<IApiResponse<string>> {
    return this.http.get('auth/oneLogin');
  }

  public oneLoginGetToken(code: string): Observable<IApiResponse<any>> {
    return this.http.get('auth/oneLoginGetToken', { code });
  }

  public mfaVerify(data: IMfaVerify): Observable<IApiResponse<any>> {
    return this.http.post('auth/mfaVerify', data);
  }
}
