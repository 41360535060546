import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { LoaderService } from '@core/services/loader.service';
import { Router } from '@angular/router';
import { catchError, filter, map, take } from 'rxjs/operators';
import { IUser } from '@core/models/IUser';
import { NotificationsService } from '@shared/service/notifications.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRequestingToken = false;
  private newTokenArrived: BehaviorSubject<string | boolean> = new BehaviorSubject(null);

  constructor(
    private auth: AuthService,
    private user: UserService,
    private _n: NotificationsService,
    private injector: Injector,
    private _msgSrv: MessageService,
    private loader: LoaderService
  ) {}

  public get router(): Router {
    return this.injector.get(Router);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    request = this.setTokenToRequest(request);
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && !request.url.includes('refreshToken')) {
            return this.handle401Error(request, next, err);
          }

          if (err.status === 401 && request.url.includes('refreshToken')) {
            this.proceedLogout();
            return of(null);
          }

          if (err.status === 403 && err.error && err.error.error === 'Concurrent login') {
            this.proceedLogout(true);
            return of(null);
          } else if (err.status === 403) {
            this._n.notify('error', '', 'You dont have permission to do this action');
          }

          return throwError(err);
        } else {
          return of(null);
        }
      })
    );
  }

  private handle401Error(request, next, err) {
    if (this.isRequestingToken) {
      return this.newTokenArrived.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          if (token === false) {
            this.loader.hide();
            return throwError(err);
          } else {
            return next.handle(this.setTokenToRequest(request, token as string));
          }
        })
      );
    } else {
      this.isRequestingToken = true;
      this.newTokenArrived.next(null);

      return this.auth.refresh().pipe(
        map(res => {
          const user: IUser = this.user.getUser;
          this.auth.updateTokens(res.data);
          this.user.setUser = { ...user, ...res.data };
          return res.data.token;
        }),
        switchMap(token => {
          this.newTokenArrived.next(token);
          this.isRequestingToken = false;
          return next.handle(this.setTokenToRequest(request, token));
        }),
        catchError(error => {
          this.proceedLogout();
          return throwError(error);
        })
      );
    }
  }

  private proceedLogout(concurrentLogin: boolean = false) {
    this.newTokenArrived.next(false);
    this.isRequestingToken = false;
    this.auth.clear();
    this._msgSrv.clear();
    this.user.setUser = null;
    const darkMode = JSON.parse(localStorage.getItem('rv-dark'));
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('rv-dark', JSON.stringify(darkMode));
    if (concurrentLogin) {
      this._n.notify('warn', '', 'Somebody else logged into your account', 'public');
    }
    this.router.navigate(['/']);
  }

  private setTokenToRequest(request, token = this.auth.token) {
    if (!token) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
