import { Injectable } from '@angular/core';
import { HttpService } from '@app/core';
import { Observable } from 'rxjs';
import { IApiResponse } from '@shared/models/IApiResponse';
import { ETableConfig } from '@shared/enums/ETableConfig';
import { ITableConfig } from '@shared/api/group-api/models';

@Injectable({
  providedIn: 'root',
})
export class GroupApiService {
  private pathTableSettings = 'group/table-settings';

  constructor(private _http: HttpService) {}

  // DESC: Get group form settings
  public getSettings(tableType: ETableConfig = ETableConfig.All): Observable<IApiResponse<ITableConfig[]>> {
    return this._http.get(`${this.pathTableSettings}`, { tableType });
  }

  // DESC: Update group table settings
  public updateSettings(data: ITableConfig[]): Observable<IApiResponse<null>> {
    return this._http.post(`${this.pathTableSettings}`, { data: data });
  }
}
