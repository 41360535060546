export class IClient {
  id: string;
  idInsured: number;
  companyNumber: number;
  idGroup: string;
  idParent: string;
  idTree: string;
  idClientLogin: string;
  idSecondAccount: string;
  secondAccount: string;
  name: string;
  address1: string;
  address2: string;
  zipCode: string;
  town: string;
  country: string;
  countryState: string;
  phone: string;
  note: string;
  note2: string;
  keyAccount: string;
  invoiceEmail: string;
  idKeyAccount: string;
  ioReference: string;
  financeRef1: string;
  localizedText1: string;
  primaryEmail: string;
  idSegmentation1: number;
  idSegmentation2: number;
  idSegmentation3: number;
  idSegmentation4: number;
  bankName: string;
  bankRegNr: string;
  bankAccount: string;
  lastEdit: Date;
  localizedDate1: Date;
  localizedDate2: Date;
  localizedDate3: Date;
  contractEmail: string;
  idCrmCategory: number;
  incorporationDate: Date;
  localizedCheck1: boolean;
  localizedCheck2: boolean;
  localizedCheck3: boolean;
  readOnly: boolean;
  isCompanyNumberValid: boolean;
  hasChildren: boolean;
  accessAllowed: boolean;

  constructor(id = '0') {
    this.id = id;
  }
}

export interface IClientCheck {
  insuredName: string;
  companyNumber: number;
}

export interface IClientGetter {
  idInsured: string;
}
