import { HttpErrorResponse } from '@angular/common/http';

export interface IApiResponse<T> {
  success: boolean;
  data: T;
  errors: IError[];
}

export interface IError {
  code: string;
  name: string;
  description: string;
}

export interface IResponseId {
  id: string;
}

export interface IErrorResponse extends HttpErrorResponse {
  error: IApiResponse<null>;
}
